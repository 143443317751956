var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(this.$route.params.id)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('directorates2'))+" "+_vm._s(this.governorate.name)+" "),_c('v-spacer')],1):_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('directorates1'))+" "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"dd",attrs:{"headers":_vm.headers,"items":_vm.directorates,"options":_vm.options,"server-items-length":_vm.directoratesTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100, 500],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"header.actions",fn:function(ref){
    var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.new_numbers",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"mx-1",attrs:{"color":"#00bcd4","label":"","small":""}},[_vm._v(" "+_vm._s(item.s_new_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"#00bcd4","label":"","small":""}},[_vm._v(" "+_vm._s(item.t_new_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"#00bcd4","label":"","small":""}},[_vm._v(" "+_vm._s(item.d_new_numbers)+" ")])]}},{key:"item.locked_numbers",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"mx-1",attrs:{"color":"error","label":"","small":""}},[_vm._v(" "+_vm._s(item.s_locked_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"error","label":"","small":""}},[_vm._v(" "+_vm._s(item.t_locked_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"error","label":"","small":""}},[_vm._v(" "+_vm._s(item.d_locked_numbers)+" ")])]}},{key:"item.active_numbers",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"mx-1",attrs:{"color":"success","label":"","small":""}},[_vm._v(" "+_vm._s(item.s_active_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"success","label":"","small":""}},[_vm._v(" "+_vm._s(item.t_active_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"success","label":"","small":""}},[_vm._v(" "+_vm._s(item.d_active_numbers)+" ")])]}},{key:"item.sent_numbers",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"mx-1",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(item.s_sent_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(item.t_sent_numbers)+" ")]),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(item.d_sent_numbers)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"info","x-small":""},on:{"click":function($event){return _vm.showSchools( item.id)}}},[_vm._v(" "+_vm._s(_vm.$('showSchools'))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-town-hall ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }